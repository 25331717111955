import { BreadcrumbBar } from 'componix'
import { UUID } from 'crypto'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetCombinedCoverageHistory, useGetComboInfo, useGetRatingHistory } from '../cache/comboCache'
import CombinedCoverageHistory from '../components/Combo/CombinedCoverageHistory'
import ComboOwnership from '../components/Combo/ComboOwnership'
import RatingHistory from '../components/Combo/RatingHistory'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import PrimaryCoverageIdentifier from '../components/PrimaryCoverageIdentifier'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'

const Combo: React.FC = () => {
  const { comboId } = useParams()
  const { pageStyle } = useNavbar()

  const {
    data: comboInfoData,
    isPending: isComboInfoLoading,
    isError: isComboInfoError,
  } = useGetComboInfo(comboId as UUID)

  const {
    data: combinedCoverageHistory,
    isPending: isCombinedCoverageHistoryLoading,
    isError: isCombinedCoverageHistoryError,
  } = useGetCombinedCoverageHistory(comboId as UUID)

  const {
    data: ratingHistoryData,
    isPending: isRatingHistoryLoading,
    isError: isRatingHistoryError,
  } = useGetRatingHistory(comboId as UUID)

  useSetAlertDetails([isRatingHistoryError, isComboInfoError, isCombinedCoverageHistoryError])

  return (
    <div style={pageStyle}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${comboInfoData?.comboId}`,
            to: `/combos/${comboInfoData?.comboGlobalId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <CombinedCoverageHistory
              currentComboId={String(comboInfoData?.comboId)}
              data={combinedCoverageHistory}
              isLoading={isCombinedCoverageHistoryLoading}
            />
            <RatingHistory data={ratingHistoryData} isLoading={isRatingHistoryLoading} />
            <ComboOwnership comboId={comboId} />
          </>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key={''}
            title="Combo Group"
            combo={{ identifier: String(comboInfoData?.comboId), guid: comboInfoData?.comboGlobalId }}
            isLoading={isComboInfoLoading}
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}

export default Combo
