import { Box } from '@mui/material'
import { BreadcrumbBar } from 'componix'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetRateData } from '../cache/rateCache'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import RateDataCard from '../components/Rates/RateDataCard'
import RateLossLimitsCard from '../components/Rates/RateLossLimitsCard'
import RatePremiumDiscountCard from '../components/Rates/RatePremiumDiscountCard'
import RateSplitPointsCard from '../components/Rates/RateSplitPointsCard'
import RateWeightsAndBallastsCard from '../components/Rates/RateWeightsAndBallastsCard'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'

const RateDetails = () => {
  const { ratePeriodId } = useParams()

  const { data: rateData, isPending, isError } = useGetRateData(ratePeriodId as string)
  useSetAlertDetails([isError])

  const ratePeriod = {
    effectiveDate: rateData?.ratingPeriodEffectiveDate,
    expirationDate: rateData?.ratingPeriodExpirationDate,
  }

  const { pageStyle } = useNavbar()
  return (
    <Box style={{ ...pageStyle, marginBottom: '24px' }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Rate Data`,
            to: `/rates`,
          },
          {
            label: `Rate Effective Date ${rateData?.ratingPeriodEffectiveDate}`,
            to: `/rates/rateDetails`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <div>
            <RateSplitPointsCard ratePeriod={ratePeriod} />
            <RateWeightsAndBallastsCard ratePeriod={ratePeriod} />
            <RatePremiumDiscountCard ratePeriod={ratePeriod} />
            <RateLossLimitsCard ratePeriod={ratePeriod} />
          </div>
        }
        sideRailComponentArray={[<RateDataCard key="rd" rate={rateData} loading={isPending} />]}
      />
    </Box>
  )
}

export default RateDetails
