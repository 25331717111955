import { BreadcrumbBar } from 'componix'
import { UUID } from 'crypto'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetCombinedCoverageHistory, useGetComboDataByCoverageId } from '../cache/comboCache'
import { useGetComboMembershipHistory } from '../cache/coverageCache'
import { ModifyCoverageCard } from '../components/Coverage/ModifyCoverage/ModifyCoverageCard'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import PrimaryCoverageIdentifier from '../components/PrimaryCoverageIdentifier'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { HierarchyTypes } from '../utils/hierarchy.enum'

export const ModifyCoverage = () => {
  const { coverageId } = useParams()
  const { pageStyle } = useNavbar()
  const navigate = useNavigate()

  const {
    data: comboData,
    isPending: isComboDataLoading,
    isError: isComboDataError,
  } = useGetComboDataByCoverageId(coverageId as string)

  const {
    data: combinedCoverageHistory,
    isPending: isCombinedCoverageHistoryLoading,
    isError: isCombinedCoverageHistoryError,
  } = useGetCombinedCoverageHistory(comboData?.comboGuid as UUID)

  const {
    lazyFetch: fetchCoverageHistory,
    data: coverageHistory,
    isPending: isCoverageHistoryLoading,
    isError: isCoverageHistoryError,
  } = useGetComboMembershipHistory(coverageId as string)

  fetchCoverageHistory()

  useSetAlertDetails([isComboDataError, isCoverageHistoryError, isCombinedCoverageHistoryError])

  const sourceCoverageData = combinedCoverageHistory?.find((cch) => cch.coverageId.toString() == coverageId)
  const getLastCombinedCoverageDate = () => {
    if (coverageHistory) {
      const dates = coverageHistory.map((ch) => ch.includeDate).sort((a, b) => dayjs(b).diff(dayjs(a)))
      return dates[1]
    }
    return undefined
  }
  const onCancel = () => {
    navigate(`/combos/${comboData?.comboGuid}`)
  }
  const { data: ancestorData } = useGetAncestors(comboData?.coverageGuid as UUID, HierarchyTypes.COVERAGE)

  return (
    <div style={pageStyle}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${comboData?.comboId}`,
            to: `/combos/${comboData?.comboGuid}`,
          },
          {
            label: `Modify Coverage ${coverageId}`,
            to: `/coverages/${coverageId}/modify`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <ModifyCoverageCard
            onCancel={onCancel}
            oldestIncludeDate={getLastCombinedCoverageDate()}
            sourceCoverageData={sourceCoverageData}
            isLoading={isCombinedCoverageHistoryLoading || isCoverageHistoryLoading}
          />
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key="pci"
            combo={ancestorData?.combo}
            coverage={ancestorData?.coverage}
            isLoading={isComboDataLoading}
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}
